<template>
  <div class="d-flex msgContent">
    <div
      class="d-flex msgLabel"
      v-for="item in List"
      :key="item.zddm"
      :class="'w-' + item.width"
    >
      <div class="msgName">
        {{ item.name }}
      </div>
      <div class="msgVal flexList line1" :title="info[item.zddm]">
        <span>{{ info[item.zddm] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  data() {
    return {
      List: [
        {
          name: "单位名称",
          zddm: "bkdw",
          width: "50",
        },
        {
          name: "单位编码",
          zddm: "bkdwdm-noshow",
          width: "50",
        },
        {
          name: "职位名称",
          zddm: "zwmc",
          width: "50",
        },
        {
          name: "职位编码",
          zddm: "zwbm",
          width: "50",
        },
        {
          name: "名额",
          zddm: "me",
          width: "50",
        },
        {
          name: "缴费金额",
          zddm: "je1",
          width: "50",
        },
        {
          name: "要求学历",
          zddm: "xl",
          width: "50",
        },
        {
          name: "要求学位",
          zddm: "xw",
          width: "50",
        },
        {
          name: "专业",
          zddm: "zy",
          width: "50",
        },
        {
          name: "范围对象",
          zddm: "fwdx",
          width: "50",
        },
        {
          name: "加试内容",
          zddm: "js",
          width: "50",
        },
        {
          name: "其他条件",
          zddm: "qttl",
          width: "50",
        },
        // {
        //   name: "资格初审单位",
        //   zddm: "msfj",
        //   width: "50",
        // },
        // {
        //   name: "备注",
        //   zddm: "msfj",
        //   width: "50",
        // },
      ],
    };
  },
};
</script>

<style></style>
