<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
/* . {
  border: 1px solid #2a5caa;
}

. {
  background-color: #2a5caa;
  color: #ffffff;
} */

.enroll-box {
  font-size: 14px;
}

.text-box {
  padding: 10px;
}

.warning-img {
  width: 50px;
  height: 50px;
}

.mianShi .el-radio {
  display: block;
}
.el-form-item {
  margin-bottom: 0;
}
label {
  margin-bottom: 0;
}
.selectW {
  min-width: 260px;
}
.el-form--label-top .el-form-item__label {
  padding: unset;
}
</style>
<script>
import YzSelect from "@/components/form/yzSelect.vue";
import YzElSelect from "@/components/form/yz-el-select.vue";
import zwInfo from "./zwInfo.vue";

import {
  getUnit,
  getPosition,
  getExamInfo,
  examApply,
  getKQ,
  specialApply,
} from "@/api/examination.js";
export default {
  components: {
    YzSelect,
    YzElSelect,
    zwInfo,
  },
  props: {
    examInfo: Object,
    sfty: "",
    isSpecial: {
      default: false,
    },
  },
  data() {
    return {
      ksbmbh: "",
      ExamInfo: {},
      kqxzform: {
        bkjbmc: "",
        bkgwxkm: "",
        bkgwxk: "",
        areaSid: "",
        bkdwzym: "",
        bkdwzy: "",
        bktj: "",
      },
      pageNum: 1,
      kqxz: true,
      kqList: [],
      dwList: [],
      zwList: [],
      dwobj: {},
      subjectList: [],
      xzksList: [], //选择考试科目
      zwInfo: {},
      submitDisable:false
    };
  },
  methods: {
    next() {
      this.submitDisable = true
      let obj = this.check();
      if (!obj.status) {
      this.submitDisable = false
        this.$message({
          message: obj.message,
          type: "warning",
        });
        return;
      }
      setTimeout( ()=>{
        this.submitApply();
      },200);//延迟200毫秒请求:主要是为了确保禁用生效
      // this.submitApply();

    },
    //查询考区限制
    getkqxk() {
      getKQ(this.ksbmbh).then((res) => {
        if (res.status) {
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              if (v.qxmc) {
                v["name"] = v.qxmc;
              } else {
                v["name"] = v.ssmc + v.dszmc;
              }
              v["value"] = v.sid;
            });
            this.kqList = res.data;
          }
        }
      });
    },
    //获取单位
    // getDwList() {
    //   getUnit({ ksbmbh: this.ksbmbh }).then((res) => {
    //     if (res.status) {
    //       let data = res.data;
    //       data.forEach((item, index) => {
    //         this.dwList.push({
    //           name: item.dwmc,
    //           value: item.dwdm,
    //           detail: item,
    //         });
    //       });
    //       this.list2[0].data = this.dwList;
    //     }
    //   });
    // },
    //获取职位
    getZwList() {
      getPosition(this.ksbmbh).then((res) => {
        let data = res.data;
        this.zwList = [];
        this.dwList = [];
        let dwobj = {};
        this.kqxzform.bkgwxkm = "";
        this.kqxzform.bkgwxk = "";
        data.forEach((item, index) => {
          item.name = item.zwmc + "(" + item.zwbm + ")" + "-" + item.bkdw;
          item.value = item.zwbm;
          if (dwobj[item.bkdwdm]) {
            dwobj[item.bkdwdm].zwlist.push(item);
          } else {
            dwobj[item.bkdwdm] = {
              name: item.bkdw + "(" + item.bkdwdm + ")",
              value: item.bkdwdm,
              bkdwdm:item.bkdwdm,
              bkdw:item.bkdw,
              zwlist: [item],
            };
          }
        });
        this.dwList = Object.values(dwobj);
        this.dwobj = dwobj;
        this.zwList = data
      });
    },

    //校验单位
    checkDW() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (this.dwList.length != 0 && !form.bkdwzym) {
        obj.status = false;
        obj.message = "请选择报考单位";
      }
      return obj;
    },
    //校验职位
    checkZW() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (this.zwList.length != 0 && !form.bkgwxkm) {
        obj.status = false;
        obj.message = "请选择报考岗位";
      }
      return obj;
    },
    //校验考区
    checkArea() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (this.kqList.length != 0) {
        if (!form.areaSid) {
          obj.status = false;
          obj.message = "请选择考区";
        } else {
          let data = this.kqList.filter((v) => {
            return v.sid == form.areaSid;
          })[0];
          if (data && data.zdksrs != 0 && data.zdksrs == data.ybmrs) {
            obj.status = false;
            obj.message = "此考区报名人数已满";
          }
        }
      }
      return obj;
    },
    check() {
      let obj = {};
      obj = this.checkDW();
      if (obj.status) {
        obj = this.checkZW();
      }
      if (obj.status) {
        obj = this.checkArea();
      }
      return obj;
    },
    goApply() {
      let obj = this.check();
      if (obj.status) {
        this.subjectList = [];
        this.zwInfo = this.zwList.filter((v) => {
          return this.kqxzform.bkgwxkm == v.zwbm;
        })[0];
        this.kqxz = false;
      }
    },
    //提交报名
    submitApply() {
      // return
      let _this = this;
      let form = _this.kqxzform;
      form.sfty = _this.sfty;
      form.ksbmbh = _this.ksbmbh;
      form.kmdm = _this.xzksList.join(",");
      if (_this.xzksList.length) {
        form.kmidList = _this.xzksList.join(",");
      }
      if (this.isSpecial) {
        specialApply(form).then((res) => {
          if (res.status) {
            let bmh = res.data.bmh;
            _this.$router.replace({
              path: "./enrollForm",
              query: {
                ksbmbh: _this.ksbmbh,
                bmh: bmh,
              },
            });
          }else{
            this.submitDisable = false
          }
        }).catch(()=>{
          this.submitDisable = false
        });
      } else {
        examApply(form).then((res) => {
          if (res.status) {
            let bmh = res.data.bmh;
            _this.$router.replace({
              path: "./enrollForm",
              query: {
                ksbmbh: _this.ksbmbh,
                bmh: bmh,
              },
            });
          }else{
            this.submitDisable = false
          }
        }).catch(()=>{
          this.submitDisable = false
        });
      }
    },
    //获取考试详情
    getExamInfo() {
      getExamInfo(this.ksbmbh).then((res) => {
        if (res.status) {
          this.ExamInfo = res.data;
          //获取考区限制列表
          this.getkqxk();
          this.getZwList();
        }
      });
    },
  },
  created() {
    this.ExamInfo = this.examInfo;
    this.ksbmbh = this.ExamInfo.ksbmbh;
    this.getExamInfo();
  },
  watch: {
    // "kqxzform.bkdwzym": {
    //   deep: true,
    //   handler(val) {
    //     this.zwList = this.dwobj[val].zwlist;
    //   },
    // },
    "kqxzform.bkgwxkm": {
      deep: true,
      handler(val) {
        let zwData= this.zwList.filter(v=>{return v.value == val})[0]
        let dwData= this.dwList.filter(x=>{return x.value == zwData.bkdwdm})[0]
        this.kqxzform.bkdwzym = dwData.value
        this.kqxzform.bkdwzy = dwData.name
        this.goApply();
      },
    },
    "kqxzform.areaSid": {
      deep: true,
      handler(val) {
        this.goApply();
      },
    },
  },
};
</script>

<style></style>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card" style="min-height: 470px">
        <div class="card-body">
          <!-- <h4 class="card-title pb-3 border-dash">
            <span class="mr-2">考试名称：{{ ExamInfo.ksmc }}</span>
          </h4> -->
          <div class="col-sm-6 mt-4 mb-3">
            <el-form ref="form" label-position="right " label-width="100px">
              <div
                class="input-group mb-2 flexList"
                v-if="kqList && kqList.length != 0"
              >
                <el-form-item label="考区选择" class="w-100">
                  <YzElSelect
                    class="selectW"
                    style="display: inline-block"
                    :dict="kqList"
                    :bound="['areaSid']"
                    v-model="kqxzform"
                  ></YzElSelect>
                  <span class="text-info ml-3">可输入关键字搜索</span>
                </el-form-item>
              </div>
              
              <div
                class="input-group mb-2 flexList"
                v-if="zwList && zwList.length != 0"
              >
                <el-form-item label="选择报考岗位" class="w-100">
                  <YzElSelect
                    class="selectW"
                    style="display: inline-block"
                    :dict="zwList"
                    :bound="['bkgwxkm', 'bkgwxk']"
                    :boundDict="['zwbm', 'zwmc']"
                    v-model="kqxzform"
                  ></YzElSelect>
                  <span class="text-info ml-3">可输入关键字或编码搜索</span>
                </el-form-item>
              </div>
              <div
                class="input-group mb-2 flexList"
                v-if="dwList && dwList.length != 0"
              >
                <el-form-item label="选择报考单位" class="w-100">
                  <YzElSelect
                    class="selectW"
                    style="display: inline-block"
                    :dict="dwList"
                    :bound="['bkdwzym', 'bkdwzy']"
                    :boundDict="['bkdwdm', 'bkdw']"
                    :readOnly="true"
                    v-model="kqxzform"
                  ></YzElSelect>
                  <!-- <span class="text-info ml-3">可输入关键字搜索</span> -->
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div v-if="!kqxz">
            <div class="enroll-box">
              <div class="mb-3" v-if="ExamInfo.sfgdks == 1">
                <div class="">
                  <div v-if="subjectList.length != 0">
                    <div class="h35 p-2">
                      <label> 科目列表（请勾选本次的考试科目） </label>
                    </div>
                    <div class="text-box">
                      <el-checkbox-group
                        v-model="xzksList"
                        class="d-flex flex-column"
                      >
                        <el-checkbox
                          :label="item.value"
                          v-for="(item, index) in subjectList"
                          :key="index"
                          :checked="
                            (xkData.sfms || xkData.jbm == '03') &&
                            !msKM[item.detail.kmdm]
                          "
                          :disabled="xkData.sfms || xkData.jbm == '03'"
                          >{{ item.name }}
                          <span class="text-warning">{{
                            msKM[item.detail.kmdm] ? "免试" : ""
                          }}</span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="kqxzform.bkgwxkm">
              <div class="mb-3">
                您当前选择:{{ kqxzform.bkgwxk }}({{ kqxzform.bkgwxkm }})
              </div>
              <div class="mb-3">职位信息仅供参考，请以公告为准！</div>
              <zwInfo :info="zwInfo"></zwInfo>
            </div>
            <div class="text-center p-2">
              <el-button type="primary" size="small" class="w-10" @click="next" :disabled="submitDisable"
                >提交并进入下一步</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
