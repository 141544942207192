<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
/* . {
  border: 1px solid #2a5caa;
}

. {
  background-color: #2a5caa;
  color: #ffffff;
} */

.enroll-box {
  font-size: 14px;
}

.text-box {
  padding: 10px;
}

.warning-img {
  width: 50px;
  height: 50px;
}

.mianShi .el-radio {
  display: block;
}
.el-form-item {
  margin-bottom: 0;
}
label {
  margin-bottom: 0;
}
.selectW {
  width: 260px;
}
.el-form--label-top .el-form-item__label {
  padding: unset;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import FormHeader from "@/views/pages/examinee/form-header.vue";
import examXK from "@/views/pages/examinee/examType/examXK";
import examZW from "@/views/pages/examinee/examType/examZW";
import { getExamInfo } from "@/api/examination.js";
export default {
  components: {
    Layout,
    PageHeader,
    FormHeader,
    examZW,
    examXK,
  },
  data() {
    return {
      title: "报名信息提交",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "基本信息",
          active: true,
        },
      ],
      query: {},
      ExamInfo: {},
      pageNum: 1,
      sfty:''
    };
  },
  methods: {
    //获取考试详情
    getExamInfo() {
      getExamInfo(this.ksbmbh).then((res) => {
        if (res.status) {
          this.ExamInfo = res.data;
        }
      });
    },
  },
  created() {
    this.query = this.$route.query;
    this.ksbmbh = this.query.ksbmbh;
    this.sfty =  this.query.sfty?JSON.parse(this.query.sfty):true
    this.getExamInfo();
  },
};
</script>

<style></style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <FormHeader :pageNum="pageNum" :examInfo="ExamInfo" />
            <examZW v-if="ExamInfo.kmxzfs == '2'" :examInfo="ExamInfo" :sfty="sfty" :isSpecial="this.query.isSpecial"></examZW>
            <examXK v-if="ExamInfo.kmxzfs == '1'" :examInfo="ExamInfo" :sfty="sfty" :isSpecial="this.query.isSpecial"></examXK>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
