<template>
  <div>
    <el-select
      v-model="value"
      filterable
      placeholder="请选择"
      v-if="!readOnly"
      class="w-100 h30"
    >
      <el-option
        v-for="item in listData"
        :key="item.value"
        :label="item.name"
        :value="item.value"
        :disabled="item.disabled"
      >
      </el-option>
    </el-select>
    <div v-else v-for="(item, i) in listData" :key="i">
      <template
        v-if="form[bound[0]] == item[boundDict[0]] && form[bound[0]] != ''"
      >
        {{ item[boundDict[1]] }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    disableList: {
      default: () => {
        return [];
      },
    },
    dict: {
      type: Array,
      required: false,
    },
    bound: Array,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
    url: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
    params: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    filter: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      listData: [],
      disableObj: {},
      value: "",
    };
  },
  methods: {
    updateData() {
      if (this.listData) {
        let seleted = this.listData.find((item) => {
          return this.form[this.bound[0]] == item[this.boundDict[0]];
        });
        if (!seleted && this.listData.length > 0) {
          this.value = "";
          for (const index in this.bound) {
            if (index < 2) {
              this.form[this.bound[index]] = "";
            } else {
              let [k1, v1] = this.bound[index].split(":");
              this.form[k1] = "";
            }
          }
        }
        if (seleted) {
          let bound = this.boundDict;
          this.value = seleted[bound[0]]
          for (const index in this.bound) {
            if (index < 2) {
              this.form[this.bound[index]] = seleted[bound[index]];
            } else {
              let [k1, v1] = this.bound[index].split(":");
              this.form[k1] = seleted.details[v1];
            }
          }
        }
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    if (this.url) {
      getRequest(this.url, this.params).then((res) => {
        if (res.status) {
          this.listData = res.data;
          this.updateData();
        }
      });
    } else {
      if (this.dict) {
        this.listData = JSON.parse(JSON.stringify(this.dict));
        // this.listData = this.listData.filter((item) => {
        //   return item.name.indexOf("请选择") != 0;
        // });
        // this.listData.unshift({
        //   name: "请选择",
        //   value: "",
        // });
        this.updateData();
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          let data = this.listData.filter((v) => {
            return val == v[this.boundDict[0]];
          })[0];
          this.bound.forEach((v, i) => {
            this.form[this.bound[i]] = data[this.boundDict[i]];
          });
        }
        this.$emit("input", this.form);
      },
    },
    dict: {
      deep: true,
      handler(newData) {
        if (newData) {
          this.listData = JSON.parse(JSON.stringify(newData));
          //   this.listData = this.listData.filter((item) => {
          //     return item.name.indexOf("请选择") != 0;
          //   });
          //   this.listData.unshift({
          //     name: "请选择",
          //     value: "",
          //   });
          this.updateData();
        }
      },
    },
    params: {
      deep: true,
      handler(params) {
        getRequest(this.url, params).then((res) => {
          if (res.status) {
            this.listData = res.data;
            this.$emit("out-filter", this.listData);
            this.updateData();
          }
        });
      },
    },
    form: {
      deep: true,
      handler(form) {
        let f = this.filter;
        if (this.filter != null) {
          this.listData = this.dict.filter((item) => {
            return !item.details || item.details[f] == form[f];
          });
        }
        this.$emit("out-filter", this.listData);
        this.updateData();
      },
    },
    disableList: {
      immediate: true,
      deep: true,
      handler(val) {
        val.forEach((v) => {
          this.disableObj[v] = v;
        });
      },
    },
  },
};
</script>

<style type="text/css" scoped>
/deep/.el-input__inner {
  height: 30px;
  line-height: 30px;
}
/deep/ .el-input__icon {
  line-height: 30px;
}
</style>
