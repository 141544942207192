<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
/* . {
  border: 1px solid #2a5caa;
}

. {
  background-color: #2a5caa;
  color: #ffffff;
} */

.enroll-box {
  font-size: 14px;
}

.text-box {
  padding: 10px;
}

.warning-img {
  width: 50px;
  height: 50px;
}

.mianShi .el-radio {
  display: block;
}
.el-form-item {
  margin-bottom: 0;
}
label {
  margin-bottom: 0;
}
.selectW {
  min-width: 260px;
}
.el-form--label-top .el-form-item__label {
  padding: unset;
}
/deep/ .el-radio__label {
  white-space: break-spaces;
  line-height: 20px;
  font-size: 12px;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import FormHeader from "@/views/pages/examinee/form-header.vue";
import YzSelect from "@/components/form/yzSelect.vue";

import {
  getSubject,
  getExamInfo,
  examApply,
  getKQ,
  getXK,
  getExamFree,
  getGradeRecord,
  applyExamFree,
  getKmList,
  specialApply,
} from "@/api/examination.js";
import { checkFile, unitsChange } from "@/libs/common";
import { uploadFiles, getUploadLimit } from "@/api/index";
export default {
  components: {
    YzSelect,
  },
  props: {
    examInfo: Object,
    sfty: "",
    isSpecial: {
      default: false,
    },
  },
  data() {
    return {
      msyyList: [],
      bktjList: [],
      freeFrom: {
        sfbkg: 0,
        dah: "",
        msdm: "",
        zsmc: "",
        qdsj: "",
        zsbh: "",
        zymc: "",
        msfj: "",
      },
      lables: [],
      DAList: [],
      zslist: [
        "资格证书名称：",
        "资格证书编号：",
        "证书专业名称：",
        "证书取得时间：",
        "证明电子材料：",
      ],
      xllist: [
        "毕业院校：",
        "证书编号：",
        "所学专业：",
        "毕业时间：",
        "证明电子材料：",
      ],
      ExamInfo: {},
      kqxzform: {
        bkjbmc: "",
        bkgwxkm: "",
        bkgwxk: "",
        areaSid: "",
        bkdwzym: "",
        bkdwzy: "",
        bktj: "",
        bktjm: "",
      },
      kqxz: true,
      kqList: [],
      xkList: [],
      xkxzList: [],
      dwList: [],
      zwList: [],
      dwobj: {},
      subjectList: [],
      xzksList: [], //选择考试科目
      xkData: {}, //选择的选科
      daData: {}, //档案数据
      msKM: {},
      passbkzy: [],
      astrict: {},
      submitDisable: false,
    };
  },
  methods: {
    next() {
      this.submitDisable = true;
      let obj = this.check();
      if (!obj.status) {
        this.$message({
          message: obj.message,
          type: "warning",
        });
        this.submitDisable = false;
        return;
      }
      let data = this.freeFrom;
      if (this.xkData.sfms) {
        if (this.xkData.sfms && !data.msdm) {
          this.$message("请选择免试原因");
          this.submitDisable = false;
          return;
        }
        let list = ["zsmc", "qdsj", "zsbh", "msfj"];
        for (const item of list) {
          if (!this.freeFrom[item]) {
            this.submitDisable = false;
            this.$message("免试证明信息不全，请继续填写");
            return;
          }
        }
        // 提交免试申请数据
        this.freeFrom.ksbmbh = this.ksbmbh;
        this.freeFrom.sfms = 1;
        setTimeout( ()=>{
          this.submitApply();
        },200);//延迟200毫秒请求:主要是为了确保禁用生效
      } else {
        let obj = this.checkTJ();
        if (!obj.status) {
          this.submitDisable = false;
          this.$message({
            message: obj.message,
            type: "warning",
          });
          return;
        }
        if (this.xzksList.length == 0) {
          this.submitDisable = false;
          this.$message("请选择考试科目");
          return;
        }
        setTimeout( ()=>{
          this.submitApply();
        },200);//延迟200毫秒请求:主要是为了确保禁用生效
      }
    },
    //查询免试理由列表/报考理由
    getFree() {
      let sfkqk = this.xkData.jbm == "02" ? 1 : 0;
      this.bktjList = [];
      getExamFree({ flbm: this.ExamInfo.flbm, sfkqk: sfkqk }).then((res) => {
        if (res.status) {
          if (this.xkData.sfms) {
            //免试原因
            this.msyyList = res.data.filter((v) => {
              return v.xkdm == this.xkData.xkdm;
            });
          } else {
            //报考理由
            res.data.forEach((item) => {
              item.name = item.msyy;
              item.value = item.msdm;
            });
            this.bktjList = res.data;
          }
        }
      });
    },
    //查询考试档案
    getGradeRecord() {
      return new Promise((reslove, reject) => {
        getGradeRecord(this.ksbmbh).then((res) => {
          if (res.status) {
            // res.data.forEach(x=>{
            //   x.zytg = ''
            //   x.km_1 = "km01"
            //   x.kmtg_1 = "111111"
            // })
            // res.data.push({
            //   dah:111111111111111,
            //   bkzy:'02',
            //   bkjb:'02',
            //   kmtg:'12313',
            // })
            this.DAList = res.data || [];
            reslove();
          }
        });
      });
    },
    //查询考区限制
    getkqxk() {
      getKQ(this.ksbmbh).then((res) => {
        if (res.status) {
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              if (v.qxmc) {
                v["name"] = v.qxmc;
              } else {
                v["name"] = v.ssmc + v.dszmc;
              }
              v["value"] = v.sid;
            });
            this.kqList = res.data;
          }
        }
      });
    },
    //获取选科
    getXKList() {
      return new Promise((re) => {
        getSubject({ flbm: this.ExamInfo.flbm }).then((res) => {
          if (res.status) {
            let xkList = res.data;
            xkList.forEach((item, index) => {
              item.name = item.xkmc + item.jbmc;
              item.value = item.xkdm;
            });
            if (this.DAList.length != 0) {
              //专业通过长度为6 表示通过
              if (
                this.DAList.filter((v) => {
                  return v.zytg && v.zytg.length == 6;
                })[0]
              ) {
                xkList = xkList.filter((x) => {
                  return x.jbm == "03";
                });
              } else {
                xkList = xkList.filter((x) => {
                  return x.jbm != "03";
                });
              }
              this.DAList.forEach((v) => {
                //专业通过长度为6 表示通过
                if (v.zytg && v.zytg.length == 6) {
                  let data = xkList.filter((x) => {
                    return x.zym == v.bkzy || x.jbm == "01" || x.jbm == "02";
                  });
                  data.forEach((z) => {
                    this.passbkzy.push(z.xkdm);
                  });
                }
              });
            } else {
              xkList = xkList.filter((x) => {
                return x.jbm != "03";
              });
            }
            if (xkList.length != 0) {
              xkList.unshift({
                name: "请选择考试专业",
                value: "",
              });
            }
            this.xkList = xkList;
            re(xkList);
          }
        });
      });
    },
    //获取选科科目
    getKmList() {
      let sid = this.xkList.filter((v) => {
        return v.value == this.kqxzform.bkgwxkm;
      })[0].sid;
      getKmList(sid).then((res) => {
        if (res.status) {
          if (res.data) {
            let list = res.data.subjectList;
            list.forEach((v) => {
              this.subjectList.push({
                name: v.kmmc,
                value: v.kmdm,
                detail: v,
              });
            });
          }
        }
      });
    },
    //校验考区
    checkArea() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (this.kqList.length != 0) {
        if (!form.areaSid) {
          obj.status = false;
          obj.message = "请选择考区";
        } else {
          let data = this.kqList.filter((v) => {
            return v.sid == form.areaSid;
          })[0];
          if (data && data.zdksrs != 0 && data.zdksrs == data.ybmrs) {
            obj.status = false;
            obj.message = "此考区报名人数已满";
          }
        }
      }
      return obj;
    },
    //校验专业
    checkZY() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (!form.bkgwxkm) {
        obj.status = false;
        obj.message = "请选择专业";
      }
      if (this.xkxzList.length != 0) {
        let data = this.xkxzList.filter((v) => {
          return v.xkdm == form.bkgwxkm;
        })[0];
        if (data && data.zdksrs != 0 && data.zdksrs == data.ybmrs) {
          obj.status = false;
          obj.message = "此专业报名人数已满";
        }
      }
      return obj;
    },
    //校验报考条件
    checkTJ() {
      let form = this.kqxzform;
      let obj = {
        status: true,
        message: "",
      };
      if (
        this.ExamInfo.kmxzfs == 1 &&
        this.bktjList.length != 0 &&
        !form.bktj
      ) {
        obj.status = false;
        obj.message = "请选择报考条件";
      }
      return obj;
    },
    check() {
      let obj = {};
      if (this.ExamInfo.kmxzfs == 1) {
        obj = this.checkZY();
        if (obj.status) {
          obj = this.checkArea();
        }
      }
      return obj;
    },
    goApply() {
      let obj = this.check();
      if (obj.status) {
        this.freeFrom.dah = "";
        this.subjectList = [];
        this.msyyList = [];
        this.daData = {};
        this.freeFrom.dah = "";
        this.freeFrom.sfbkg = 0;
        this.xkData = this.xkList.filter((v) => {
          return v.value == this.kqxzform.bkgwxkm;
        })[0];
        //考试级别、专业一致时记录档案号
        //判断级别是否一致
        let daData = this.DAList.filter((v) => {
          return v.bkjb == this.xkData.jbm ;
        });
        if (daData.length != 0) {
          //判断专业是否一致
          let zyList = daData.filter(x=>{return x.bkzy == this.xkData.zym})
          if(zyList.length != 0){
            let kmtgList = []
            for (let index = 0; index < zyList.length; index++) {
              for (const key in zyList[index]) {
                //判断是否有科目通过
                if (key.includes('kmtg_') && zyList[index][key]) {
                  kmtgList.push(zyList[index])
                  break
                }
              }
            }
            this.daData = kmtgList.length != 0? kmtgList[kmtgList.length - 1] : zyList[zyList.length - 1]
          }else{
            this.daData = daData[daData.length - 1];
          }
          if (this.xkData.jbm == "03") {
            // 增报专业
            this.freeFrom.dah = "";
          } else {
            // 非增报
            this.freeFrom.dah = this.daData.dah;
          }
          this.freeFrom.sfbkg = 1;
        }
        this.kqxz = false;

        //是否滚动考试
        if (this.ExamInfo.kmxzfs == 1) {
          this.getKmList();
        }
        //是否免试
        if (this.xkData.sfms && this.xkData.jbm == "01") {
          this.getLimit().then(() => {
            this.getFree();
          });
        } else if (this.xkData.jbm == "02") {
          this.getFree();
        }
      }
    },
    //提交报名
    submitApply() {
      let _this = this;
      let form = _this.kqxzform;
      form.sfty = _this.sfty;
      form.ksbmbh = _this.ksbmbh;
      form.kmdm = _this.xzksList.join(",");
      form.xksid = _this.xkData.sid;
      form.sfbkg = _this.freeFrom.sfbkg;
      form.dah = _this.freeFrom.dah;
      if (_this.xkData.sfms) {
        form.msdm = _this.freeFrom.msdm;
      }
      if (_this.xzksList.length) {
        form.kmidList = _this.xzksList.join(",");
      }

      if (this.isSpecial) {
        specialApply(form).then((res) => {
          if (res.status) {
            let bmh = res.data.bmh;
            _this.freeFrom.bmh = bmh;
            if (_this.xkData.sfms) {
              applyExamFree(_this.freeFrom).then((res) => {
                if (res.status) {
                  _this.$router.replace({
                    path: "./enrollForm",
                    query: {
                      ksbmbh: _this.ksbmbh,
                      bmh: bmh,
                    },
                  });
                }
              });
            } else {
              _this.$router.replace({
                path: "./enrollForm",
                query: {
                  ksbmbh: _this.ksbmbh,
                  bmh: bmh,
                },
              });
            }
          }else{
            _this.submitDisable = false;
          }
        }).catch(()=>{
          _this.submitDisable = false;
        });
      } else {
        examApply(form).then((res) => {
          if (res.status) {
            let bmh = res.data.bmh;
            _this.freeFrom.bmh = bmh;
            if (_this.xkData.sfms) {
              applyExamFree(_this.freeFrom).then((res) => {
                if (res.status) {
                  _this.$router.replace({
                    path: "./enrollForm",
                    query: {
                      ksbmbh: _this.ksbmbh,
                      bmh: bmh,
                    },
                  });
                }
              });
            } else {
              _this.$router.replace({
                path: "./enrollForm",
                query: {
                  ksbmbh: _this.ksbmbh,
                  bmh: bmh,
                },
              });
            }
          }else{
            _this.submitDisable = false;
          }
        }).catch(()=>{
          _this.submitDisable = false;
        });
      }
    },
    //获取考试详情
    getExamInfo() {
      getExamInfo(this.ksbmbh).then((res) => {
        if (res.status) {
          this.ExamInfo = res.data;
          //获取考区限制列表
          this.getkqxk();

          //获取考试报名表单
          if (this.ExamInfo.kmxzfs == "1") {
            if (res.data.sfgdks) {
              this.getGradeRecord().then(() => {
                this.getXKList();
              });
            } else {
              this.getXKList();
            }
            //获取选科限制列表
            getXK(this.ksbmbh).then((res) => {
              if (res.status) {
                this.xkxzList = res.data;
              }
            });
          } else {
            this.getZwList();
          }
        }
      });
    },
    //获取文件限制
    getLimit() {
      return new Promise((reslove, reject) => {
        if (!this.astrict.msfj) {
          getUploadLimit({ module: "ExamFree" }).then((res) => {
            if (res.status) {
              this.astrict.msfj = {};
              this.astrict.msfj["ext"] =
                "." + res.data.extLimit.replaceAll(",", ",.");
              this.astrict.msfj["size"] = res.data.sizeLimit;
              let size = unitsChange(res.data.sizeLimit);
              this.astrict.msfj["text"] =
                "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
              reslove();
            }
          });
        }
        reslove();
      });
    },
    //上传免试附件
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        fileRule = "ExamFree";
        uploadFiles(fileRule, file, _this.ksbmbh).then((res) => {
          if (res.status) {
            let resFile = res.data[0];
            _this.$message({ type: "success", message: "上传成功" });
            _this.freeFrom[key] = resFile.fileId;
          }
        });
      } else {
        _this.$message({ type: "error", message: result.message });
      }
      return false;
    },
  },
  created() {
    this.ExamInfo = this.examInfo;
    this.ksbmbh = this.ExamInfo.ksbmbh;
    this.getExamInfo();
  },
  watch: {
    "kqxzform.bkgwxkm": {
      deep: true,
      handler(val) {
        this.xzksList = [];
        this.freeFrom.msdm = "";
        this.kqxzform.bkjbmc = this.xkList.filter((v) => {
          return v.value == val;
        })[0].jbmc;
        this.goApply();
      },
    },
    "kqxzform.areaSid": {
      deep: true,
      handler(val) {
        this.goApply();
      },
    },
    "kqxzform.bktjm": {
      deep: true,
      handler(val) {
        let data = this.bktjList.filter((v) => {
          return v.msdm == val;
        })[0];
        if (data) {
          this.kqxzform.bktj = data.msyy;
        }
      },
    },
    "freeFrom.msdm": {
      deep: true,
      handler(val) {
        let data = this.msyyList.filter((v) => {
          return v.msdm == val;
        })[0];
        this.msKM = {};

        if (data) {
          this.xzksList = [];
          this.subjectList.forEach((v) => {
            this.xzksList.push(v.value);
          });
          data.subjectBeanList.forEach((v) => {
            this.msKM[v.kmdm] = v.kmdm;
          });
          this.freeFrom.txlx = data.txlx;
          this.lables = data.txlx == "1" ? this.zslist : this.xllist;
        }

        this.xzksList = this.xzksList.filter((v) => {
          return !this.msKM[v];
        });
        this.$forceUpdate();
      },
    },
  },
};
</script>

<style></style>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card" style="min-height: 470px">
        <div class="card-body">
          <!-- <h4 class="card-title pb-3 border-dash">
            <span class="mr-2">考试名称：{{ ExamInfo.ksmc }}</span>
          </h4> -->
          <div class="col-sm-6 mt-4 mb-3">
            <el-form ref="form" label-position="right " label-width="180px">
              <div
                class="input-group mb-2 flexList"
                v-if="kqList && kqList.length != 0"
              >
                <el-form-item label="考区选择">
                  <yz-select
                    class="selectW"
                    :dict="kqList"
                    :bound="['areaSid']"
                    v-model="kqxzform"
                  ></yz-select>
                </el-form-item>
              </div>
              <div
                class="input-group mb-2 flexList"
                v-if="xkList && xkList.length != 0"
              >
                <el-form-item label="报考专业（报考级别）">
                  <yz-select
                    class="selectW"
                    :dict="xkList"
                    :bound="['bkgwxkm', 'bkgwxk']"
                    :disableList="passbkzy"
                    v-model="kqxzform"
                  ></yz-select>
                  <span v-if="freeFrom.dah">档案号：{{ freeFrom.dah }}</span>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div v-if="!kqxz">
            <div class="enroll-box">
              <div class="mb-3" v-if="ExamInfo.sfgdks == 1">
                <div class="">
                  <div v-if="subjectList.length != 0">
                    <div class="h35 p-2">
                      <label> 科目列表（请勾选本次的考试科目） </label>
                    </div>
                    <div class="text-box">
                      <el-checkbox-group
                        v-model="xzksList"
                        class="d-flex flex-column"
                      >
                        <el-checkbox
                          :label="item.value"
                          v-for="(item, index) in subjectList"
                          :key="index"
                          :checked="
                            (xkData.sfms || xkData.jbm == '03') &&
                            !msKM[item.detail.kmdm]
                          "
                          :disabled="xkData.sfms || xkData.jbm == '03'"
                          >{{ item.name }}
                          <span class="text-warning">{{
                            msKM[item.detail.kmdm] ? "免试" : ""
                          }}</span>
                          <span>{{item.detail.zym}}</span>
                          <span>{{item.detail.jbm}}</span>
                          <span
                            v-if="
                              examInfo.sfxstgsj &&
                              item.detail.xkdm == daData['bkzy'] &&
                              daData['kmtg_' + (index + 1)]
                            "
                            class="text-primary"
                            >(通过时间：{{ daData["kmtg_" + (index + 1)] }})</span
                          >
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="bktjList && bktjList.length != 0">
                <div class="h35 p-2">
                  <label>报考条件设置</label>
                </div>
                <div class="text-box">
                  <div>
                    <div class="mb-2">请选择您报考的条件:</div>
                    <div class="mianShi">
                      <table
                        class="table table-has-bg light-table table-hover table-bordered"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th style="width: 4%">序号</th>
                            <th style="width: 96%">报考条件</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in bktjList" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <el-radio
                                :label="item.msdm"
                                v-model="kqxzform.bktjm"
                                >{{ item.msyy }}
                              </el-radio>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="msyyList.length">
                <div class="h35 p-2">
                  <label>免考科目设置</label>
                </div>
                <div class="text-box">
                  <div>
                    <div class="mb-2">请选择您免考的原因:</div>
                    <div class="mianShi">
                      <table
                        class="table table-has-bg light-table table-hover table-bordered"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th style="width: 4%">序号</th>
                            <th style="width: 46%">免试原因</th>
                            <th style="width: 20%">所属专业</th>
                            <th style="width: 10%">免试类型</th>
                            <th style="width: 20%">免试科目</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in msyyList" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <el-radio
                                :label="item.msdm"
                                v-model="freeFrom.msdm"
                                >{{ item.msyy }}
                              </el-radio>
                            </td>
                            <td>
                              {{ item.xkmc }}
                            </td>
                            <td>
                              {{ item.txlx == 1 ? "证书" : "学历" }}
                            </td>
                            <td>
                              <span
                                v-for="(t, i) in item.subjectBeanList"
                                :key="i"
                                >{{ t.kmmc }}
                                {{
                                  i + 1 == item.subjectBeanList.length
                                    ? ""
                                    : "/"
                                }}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="text-box" v-if="this.xkData.sfms && freeFrom.msdm">
                  <el-form
                    ref="form"
                    label-position="right"
                    label-width="120px"
                  >
                    <div class="mianShi" style="width: 500px">
                      <el-form-item :label="lables[0]">
                        <el-input v-model="freeFrom.zsmc"></el-input>
                      </el-form-item>

                      <el-form-item :label="lables[1]">
                        <el-input v-model="freeFrom.zsbh"></el-input>
                      </el-form-item>
                      <el-form-item :label="lables[2]">
                        <el-input v-model="freeFrom.zymc"></el-input>
                      </el-form-item>
                      <el-form-item :label="lables[3]">
                        <el-date-picker
                          v-model="freeFrom.qdsj"
                          type="date"
                          placeholder="选择日期"
                          format="yyyy - MM - dd "
                          value-format="yyyy-MM-dd"
                          class="w-100"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item :label="lables[4]">
                        <div>
                          <el-upload
                            class="upload-demo"
                            action=""
                            :auto-upload="false"
                            :show-file-list="false"
                            :accept="astrict.msfj.ext"
                            :on-change="
                              (file) => {
                                return uploadFile(file, 'msfj');
                              }
                            "
                          >
                            <el-button size="small"
                              ><i
                                class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"
                              ></i
                              >上传免试申请附件</el-button
                            >
                            <a
                              v-if="freeFrom.msfj"
                              class="ml-3"
                              slot="tip"
                              @click="
                                $yzImgDialog().show(getFileUrl(freeFrom.msfj))
                              "
                              href="javascript:;"
                              >查看文件</a
                            >
                            <div slot="tip" class="el-upload__tip">
                              <span>{{ astrict.msfj.text }}</span>
                            </div>
                          </el-upload>
                        </div>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="text-center p-2">
              <el-button
                type="primary"
                size="small"
                class="w-10"
                @click="next"
                :disabled="submitDisable"
                >提交并进入下一步</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
